body {
  font-family: 'Montserrat';
  background: #0C0C0C !important;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
  border-radius: 16px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #8c8c8c;
}

.grounded-radiants {
  position: relative;
  border: 4px solid transparent;
  border-radius: 16px;
  background: linear-gradient(orange, violet);
  background-clip: padding-box;
  padding: 10px;
  /* just to show box-shadow still works fine */
  box-shadow: 0 3px 9px black, inset 0 0 9px white;
}

.my-input::placeholder {
  color: #4C4C4C;
  opacity: 1;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

// ::-webkit-scrollbar {
//   display: none;
// }

.all-nft-text {
  background: linear-gradient(332deg, #04D3FF 19.71%, #3AFF65 115.24%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text {
  background: linear-gradient(332deg, #04D3FF 19.71%, #3AFF65 115.24%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.unlink-wallet-modal {
  max-width: 576px !important;
  border-radius: 36px !important;
  border: 1px solid rgba(255, 255, 255, 0.10) !important;
  background: #242424 !important;
  box-shadow: 0px 15px 44px 0px rgba(0, 0, 0, 0.55) !important;
  padding: 36px 24px 24px 24px !important;

  .modal-body {
    display: flex !important;
    flex-direction: column !important;
    padding: unset !important;
  }

  .modal-footer {
    margin-top: 24px;
    padding: unset !important;
    display: flex;
    gap: 10px;
    flex-direction: row;

    button {
      width: 50%;
    }

    .cancel-btn {
      border-radius: 15px;
      border: 0.5px solid rgba(255, 255, 255, 0.20);
      background: #222;
      height: 56px;
      color: white;
      font-size: 20px;
      font-weight: 600;
    }

    .unlink-btn {
      border-radius: 15px;
      background: #E13838;
      height: 56px;
      color: white;
      font-size: 20px;
      font-weight: 600;
    }
  }
}

@media screen and (min-width: 150px) and (max-width: 600px) {
  .unlink-wallet-modal {
    max-width: 100% !important;
    margin: 0px 12px;

    .modal-footer {
      display: flex;
      flex-direction: column;

      button {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 770px) and (max-width: 999px) {
  .unlink-wallet-modal {
    max-width: 560px !important;
    margin: 0px 12px;

    .modal-footer {
      display: flex;
      flex-direction: column;

      button {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 999px) and (max-width: 1280px) {
  .single-competition-hero-img {
    left: -20px !important;
  }
}


@media screen and (min-width: 1280px) and (max-width: 1400px) {
  .single-competition-hero-img {
    left: 150px !important;
  }
}

@media screen and (min-width: 1400px) and (max-width: 3000px) {
  .single-competition-hero-img {
    left: 150px !important;
  }
}


.css-1vax357:focus-visible {
  z-index: 1;
  border-color: #FFFFFF17 !important;
  box-shadow: 0 0 0 1px #3182ce;
}



