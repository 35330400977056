.browse-nft-modal {
    margin-top: 100px;
    border: var(--Size-Line-Width-lineWidth, 1px) solid rgba(255, 255, 255, 0.10)  !important;
    box-shadow: 0px 15px 44px 0px rgba(0, 0, 0, 0.55) !important;
    max-width: 1400px !important;
    border-radius: 36px !important;
    background: #242424 !important;
    padding: 24px !important;
    margin-left: 10px;
    margin-right: 10px;
    .modal-body {
      padding: unset !important;
    }
}

.empty-browse-modal {
  max-width: 450px !important;
  border-radius: 24px !important;
  border: 1px solid rgba(255, 255, 255, 0.10) !important;
  background: #242424 !important;
  box-shadow: 0px 15px 44px 0px rgba(0, 0, 0, 0.55) !important;
  padding: 24px 0px !important;
  // min-height: 100px;
  // height: 100px;
  .modal-body {
    display: flex !important;
    flex-direction: column !important;
    padding: unset !important;
  }
}

.loading-modal {
  border: var(--Size-Line-Width-lineWidth, 1px) solid rgba(255, 255, 255, 0.10)  !important;
  box-shadow: 0px 15px 44px 0px rgba(0, 0, 0, 0.55) !important;
  max-width: 1400px !important;
  border-radius: 36px !important;
  background: #242424 !important;
  max-width: 546px !important;
  padding: 24px !important;
  .modal-body {
    padding: unset !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 999px) {
  .loading-modal {
    max-width: 500px !important;
    margin: 0px 12px;
    .modal-footer {
      display: flex;
    }
  }
  .empty-browse-modal {
    max-width: 500px !important;
    margin: 0px 12px;
  }
}

@media screen and (min-width: 150px) and (max-width: 770px) {
    .browse-nft-modal {
        border-radius: unset !important;
        padding: 12px !important;
        margin-left: unset !important;
        margin-right: unset !important;
        .modal-body {
          padding: unset !important;
        }
    }
    .loading-modal {
      max-width: 100% !important;
      margin: 0px 12px;
    }
    .empty-browse-modal {
      max-width: 80% !important;
      min-height: unset !important;
      margin: 0px 12px;
  }
}
