.leader-board-drawer {
    width: 586px !important;
    border-left: var(--Size-Line-Width-lineWidth, 1px) solid rgba(255, 255, 255, 0.10) !important;
    background: #212121 !important;
}

.custom-scroll {
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
  .custom-scroll::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }