.submit-confirmation-modal {
    margin-top: 120px !important;
    box-shadow: 0px 15px 44px 0px rgba(0, 0, 0, 0.55) !important;
    max-width: 546px !important;
    border-radius: 36px !important;
    background: transparent !important;
    margin-left: 10px;
    margin-right: 10px;
    .modal-body {
        background: transparent !important;
      }
}

@media screen and (min-width: 600px) and (max-width: 999px) {
    .submit-confirmation-modal {
      max-width: 500px !important;
      margin: 0px 12px;
      .modal-footer {
        display: flex;
      }
    }
}

@media screen and (min-width: 150px) and (max-width: 600px) {
.submit-confirmation-modal {
    max-width:  100% !important;
    // border: 1px solid blue !important;
    margin: 0px 12px;
}
}

