.submit-status-modal {
    max-width: 500px !important;
    border-radius: 36px !important;
    border: 1px solid rgba(255, 255, 255, 0.10) !important;
    background: #242424 !important;
    box-shadow: 0px 15px 44px 0px rgba(0, 0, 0, 0.55) !important;
    padding: 36px 24px 24px 24px !important;
    .modal-body {
      display: flex !important;
      flex-direction: column !important;
      padding: unset !important;
    }
    .modal-footer {
      margin-top: 24px;
      padding: unset !important;
      display: flex;
      gap: 10px;
      flex-direction: row;
      button {
        width: 50%;
      }
      .browse-btn {
        border-radius: 15px;
        border: 0.5px solid rgba(255, 255, 255, 0.20);
        // background: #222;
        height: 52px;
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .mint-now {
        border-radius: 15px;
        background: #118BCF;
        height: 52px;
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
}


  
@media screen and (min-width: 600px) and (max-width: 999px) {
    .submit-nft-modal {
      max-width: 500px !important;
      margin: 0px 12px;
      .modal-footer {
        display: flex;
      }
    }
}

@media screen and (min-width: 150px) and (max-width: 600px) {
.submit-nft-modal {
    max-width:  100% !important;
    margin: 0px 12px;
    .modal-footer {
    display: flex;
    flex-direction: column-reverse;
    button {
        width: 100%;
    }
    }
}
}