.mint-now-modal {
    background: transparent !important;
    width: 500px !important;
    height: 400px !important;
}

@media screen and (min-width: 600px) and (max-width: 999px) {
    .mint-now-modal {
        background: transparent !important;
        width: 500px !important;
        height: 400px !important;
    }
}

@media screen and (min-width: 150px) and (max-width: 600px) {
    .mint-now-modal {
        background: transparent !important;
        width: 400px !important;
        height: 400px !important;
    }
}